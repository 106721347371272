import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@material-ui/core";

const ConfirmDialog = ({ open, setOpen, startTime, setStartTime, duration, team, homeName, visitorName, timeOutput, addPenalty }) => {
  const [playerNo, setPlayerNo] = useState('')

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{duration} min - {team === 'Home' ? homeName : visitorName}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              value={startTime}
              onChange={e => setStartTime(e.target.value)}
              label={'Start'}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={playerNo}
              autoFocus
              onChange={e => setPlayerNo(e.target.value)}
              type={"number"}
              label={'Spielernummer'}
              inputProps={{ inputMode: 'numeric' }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} onClick={() => {
          addPenalty(playerNo)
          setPlayerNo('')
        }}>Speichern</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
