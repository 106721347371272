import React, {useEffect, useState} from "react";
import db from "../lib/firebaseconfig";
import {replaceUmlauts} from "../lib/helpers";
import {useHomeScore, useTime, useVisitorScore} from "../Websocket";

Number.prototype.pad = function (size) {
  var s = String(this);
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
}

const LiveStreamProvider = ({useSocket, timeOutput, home, visitor, homeName, visitorName, period, gameID, mirrorDataScore, mirrorStreamScore, mirrorStreamNames, font = 'default', data}) => {
  const time = useTime();
  const homeScore = useHomeScore();
  const visitorScore = useVisitorScore();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const hideClock = urlParams.get('hideclock')
  const hideBanner = urlParams.get('hidebanner')

  // if (!pushed) {
  // banner.push({
  //   Time: '15:21',
  //   TopBar: 'Tor für Baltic Storms',
  //   BottomBarLeft: '6  Pascal Riedel',
  //   BottomBarRight: '21  Moritz Haupt',
  //   DisplayStart: 0,
  //   DisplayEnd: 0
  // })
  //   setPushed(1)
  // }

  const workSansFont = {
    fontFamily: 'Work\ Sans',
    textTransform: 'uppercase',
    fontStyle: 'italic',
    fontWeight: 'regular',
    color: '#fff',
    fontSize: '36px'

  }

  console.log(mirrorDataScore)

  return (
    <div style={workSansFont}>
      <table>
        <tr>
          <td className={'data-bar-home__team'}>{homeName}</td>
          <td className={'data-bar-home__score'} style={{paddingLeft: '50px'}}>
            {
              useSocket
                ? (!mirrorDataScore ? homeScore : visitorScore)
                : (!mirrorDataScore ? home : visitor)
            }
          </td>
        </tr>
        <tr>
          <td className={'data-bar-visitor__team'}>{visitorName}</td>
          <td className={'data-bar-visitor__score'} style={{paddingLeft: '50px'}}>
            {
              useSocket
                ? (!mirrorDataScore ? visitorScore : homeScore)
                : (!mirrorDataScore ? visitor : home)
            }
          </td>
        </tr>
      </table>
    </div>
  );
}

export default LiveStreamProvider;
