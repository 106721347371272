import React, {useEffect, useMemo, useState} from 'react';
import {useQuery} from 'urql';
import gql from 'graphql-tag';
import moment from "moment";
import {FormControl, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import db from "../lib/firebaseconfig";

const readGameDays = gql`
  query ReadSpieltages($minDate: String!){
    readSpieltages(filter: {date: {gte: $minDate}}) {
      date
      place
      games {
        id
        begin
        home {
          name
          logoSvgUrl
          logoUrl
        }
        visitor {
          name
          logoSvgUrl
          logoUrl
        }
      }
    }
  }
`

const GameSelector = ({gameID}) => {
  const [gamedays] = useQuery({
    query: readGameDays,
    variables: {
      minDate: moment().format('YYYY-MM-DD')
    }
  })

  const config = useMemo(() => db.ref(`/config`), []);

  if (!gamedays.data || gamedays.data.readSpieltages.length === 0) {
    return null;
  }

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Spiel auswählen</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={gameID}
        >
          {gamedays.data?.readSpieltages.map(spieltag => (
            spieltag.games?.map(game => (
              <MenuItem value={game.id} style={{display: 'block'}} onClick={() => config.update({
                'CurrentGame': game.id,
                'Home': game.home.name,
                'HomeLogo': game.home.logoSvgUrl ? game.home.logoSvgUrl : game.home.logoUrl || '',
                'Visitor': game.visitor.name,
                'VisitorLogo': game.visitor.logoSvgUrl ? game.visitor.logoSvgUrl : game.visitor.logoUrl || '',
              })}>
                {/*{console.log(game.home)}*/}
                <Typography component={'span'} style={{width: '100%'}} variant={"body1"}>{`${moment(spieltag.date, 'YYYY-MM-DD').format('DD.MM.YYYY')} - ${spieltag.place}`}</Typography>
                <br/>
                <Typography component={'span'} style={{width: '100%'}} variant={"body2"}>{`${game.home.name} vs. ${game.visitor.name}`}</Typography>
              </MenuItem>

            ))
          ))}
        </Select>
      </FormControl>
    </>
  )
}

GameSelector.propTypes = {}

export default GameSelector;
