import React, {useEffect, useState} from 'react';
import moment from "moment";
// import {useWSSend} from "../Websocket";

const SocketSenderProvider = ({}) => {
  const [time, setTime] = useState('00:00')
  const [stepper, setStepper] = useState(null)

  // const sendData = useWSSend()

  // useEffect(() => {
  //   const ws = new WebSocket('wss://gameboard.schokoriedel.de/');
  //   const listener = setInterval(() => {
  //     const newTime = moment(time, 'm:ss').add(1, 'seconds')
  //     if (ws.readyState === 1) {
  //       ws.send('test')
  //     }
  //   }, 1000)
  //
  //   return () => clearInterval(listener)
  // }, [sendData])

  // const connect = () => {
  //   const ws = new WebSocket('wss://gameboard.schokoriedel.de/');
  //
  //   ws.addEventListener('open', () => {
  //     console.log('connected');
  //
  //     // const interval = setInterval(() => {
  //     //   const newTime = moment(time, 'm:ss').add(1, 'seconds')
  //     //   if (ws.readyState === 1) {
  //     //     console.log(ws.readyState)
  //     //     // ws.send({
  //     //     //   minutes: newTime.format('ss'),
  //     //     //   seconds: newTime.format('mm')
  //     //     // })
  //     //   }
  //     // }, 1000)
  //     // setStepper(interval)
  //   });
  //
  //   ws.addEventListener('close', () => {
  //     console.log('disconnected');
  //     // connect();
  //   });
  //
  //
  //
  //   ws.addEventListener('error', error => {
  //     console.error('Socket encountered error: ', error.message, 'Closing socket');
  //     ws.close();
  //     clearInterval(stepper)
  //     // connect();
  //   })
  // }


  return (
    <>
      TODO: SocketSenderProvider
    </>
  );
}

export default SocketSenderProvider;
