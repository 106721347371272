export const timeToMs = time => {
  const part = time.split('.')
  const arr = part[0].split(':')
  const min = parseInt(arr[0], 10) * 60000
  const sec = parseInt(arr[1], 10) * 1000
  return part.length > 1 ? min + sec + parseInt(part[1], 10) : min + sec;
}

export const replaceUmlauts = string => {
  //let value = string.toLowerCase();
  //value = value.replace(/ä/g, 'ae');
  //value = value.replace(/ö/g, 'oe');
  //value = value.replace(/ü/g, 'ue');
  return string;
}
