import React, {useEffect, useMemo, useState} from "react";
import db, {dbTime} from "../lib/firebaseconfig";
import Paper from '@material-ui/core/Paper';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {Button, Divider, Switch, Typography} from "@material-ui/core";
import Passwort from "./Passwort";
import firebase from "firebase";
import GameSelector from "./GameSelector";
import Penalty from "../Penalty";
import {useHomeScore, useTime, useVisitorScore} from "../Websocket";

const EditApp = ({useSocket, gameID, smGameID, smLeagueID, home, visitor, homeName, visitorName, period, timeOutput, isPlaying, toggleTimer, clearTimer, updateGoals, mirrorScore, mirrorNames, mirrorStreamScore, mirrorDataScore, mirrorStreamNames, hidePenalties}) => {
  const [fetchingVideoWall, setFetchingVideoWall] = useState(false);
  const [loginSubmitted, setLoginSubmitted] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [localGameID, setLocalGameID] = useState(() => gameID);
  const [localSMGameID, setLocalSMGameID] = useState(() => smGameID);
  const [localSMLeagueID, setLocalSMLeagueID] = useState(() => smLeagueID);
  const [localTime, setLocalTime] = useState('');

  const [newBannerTime, setNewBannerTime] = useState('');
  const [newBannerTop, setNewBannerTop] = useState('');
  const [newBannerBottomLeft, setNewBannerBottomLeft] = useState('');
  const [newBannerBottomRight, setNewBannerBottomRight] = useState('');

  const [localHomeName, setLocalHomeName] = useState('');
  const [localVisitorName, setLocalVisitorName] = useState('');
  const [localPeriod, setLocalPeriod] = useState('');

  const [bannerList, setBannerList] = useState([]);
  const [playingList, setPlayingList] = useState([]);

  const time = useTime();
  const homeScore = useHomeScore();
  const visitorScore = useVisitorScore();

  const banner = db.ref(`/banner/${gameID}`)

  useEffect(() => {
    setUserToken(window.localStorage.getItem('token'))
  }, [])

  useEffect(() => {
    if (localGameID !== gameID) {
      setLocalGameID(gameID)
    }
  }, [gameID])

  useEffect(() => {
    if (localSMGameID !== smGameID) {
      setLocalSMGameID(smGameID)
    }
  }, [smGameID])

  useEffect(() => {
    if (localSMLeagueID !== smLeagueID) {
      setLocalSMLeagueID(smLeagueID)
    }
  }, [smLeagueID])

  useEffect(() => {
    if (localHomeName !== homeName) {
      setLocalHomeName(homeName)
    }
  }, [homeName])

  useEffect(() => {
    if (localVisitorName !== visitorName) {
      setLocalVisitorName(visitorName)
    }
  }, [visitorName])

  useEffect(() => {
    if (localPeriod !== period) {
      setLocalPeriod(period)
    }
  }, [period])

  const config = useMemo(() => db.ref(`/config`), []);

  if (!gameID) {
    return null;
  }

  let isLoggedIn = firebase.auth().onAuthStateChanged(state => {
    if (!state) {
      setLoginSubmitted(false)
      return false;
    }

    setLoginSubmitted(true)
    return true;
  })

  if (!loginSubmitted) {
    return <Passwort open setLoginSubmitted={setLoginSubmitted} />
  }

  const updateSaisonmanager = () => {
    fetch(`https://saisonmanager.de/api/v2/games/${localSMGameID}.json`).then(res => {
      res.json().then(json => {
        console.log(json)
        const homeLogo = json.home_team_logo
        const guestLogo = json.guest_team_logo
        const homeName = json.home_team_name
        const guestName = json.guest_team_name

        config.update({
          'CurrentSMGame': localSMGameID,
          'CurrentSMLeague': localSMLeagueID,
          'Home': json && homeName ? `${homeName}` : '',
          'Visitor': json && guestName ? `${guestName}` : '',
          'HomeLogo': json && homeLogo ? `https://saisonmanager.de${homeLogo}` : '',
          'VisitorLogo': json && guestLogo ? `https://saisonmanager.de${guestLogo}` : '',
        })
      })
    })

  }


  // if (!firebase.auth().currentUser) {
  //   return <Passwort open={!firebase.auth().currentUser} setUserToken={setUserToken} />
  // }


  // banner.on('value', (snapshot) => {
  //   const values = snapshot.val();
  //   const localList = []
  //   const playingListTmp = []
  //   if (values) {
  //     const keys = Object.keys(snapshot.val());
  //     keys.forEach(key => {
  //       if (values[key].DisplayEnd === 0) {
  //         let tmp = values[key];
  //         tmp.ID = key
  //         localList.push(tmp)
  //       } else {
  //         let tmp = values[key];
  //         tmp.ID = key
  //         playingListTmp.push(tmp)
  //       }
  //     })
  //   }
  //
  //   const reversedLocalList = localList.reverse()
  //   if (JSON.stringify(reversedLocalList) !== JSON.stringify(bannerList)) {
  //     setBannerList(reversedLocalList)
  //   }
  //
  //   const reversedPlayingList = playingListTmp.reverse()
  //   if (JSON.stringify(reversedPlayingList) !== JSON.stringify(playingList)) {
  //     setPlayingList(reversedPlayingList)
  //   }
  // });



  //const toggleBannerItem = id => {
  //  const itemIndex = bannerList.findIndex(item => {
  //    return item.ID === id
  //  })
//
  //  playingList.map(item => {
  //    if (item.ID !== id && moment(item.DisplayEnd, 'x').format('x') >= moment().format('x')) {
  //      banner.child(item.ID).update({
  //        DisplayEnd: dbTime
  //      })
  //    }
  //  })
//
//
  //  // if (bannerList[itemIndex].DisplayStart === 0) {
  //    const bla = banner.child(id).update({
  //      DisplayStart: dbTime
  //    }, error => {
  //      if (!error) {
  //        banner.child(id).get().then(snap => {
  //          const start = moment(snap.val().DisplayStart, 'x')
  //          banner.child(id).update({
  //            DisplayEnd: start.add(15, 'second').format('x')
  //          })
  //        })
  //      }
  //    })
//
  //  // }
  //}

  // const addBannerItem = e => {
  //   e.preventDefault();
  //   banner.push({
  //     Time: newBannerTime,
  //     TopBar: newBannerTop,
  //     BottomBarLeft: newBannerBottomLeft,
  //     BottomBarRight: newBannerBottomRight,
  //     DisplayStart: 0,
  //     DisplayEnd: 0
  //   }).then(value => {
  //     setNewBannerTime('')
  //     setNewBannerTop('')
  //     setNewBannerBottomLeft('')
  //     setNewBannerBottomRight('')
  //   })
  // }

  return (
    <div className="EditApp">
      <Container maxWidth={'xl'}>
        <Grid container spacing={5} alignContent={"space-around"}>

          {/*<Grid item xs={12}>*/}
          {/*  <Grid container spacing={2}>*/}
          {/*    <Grid item xs={12}>*/}
          {/*      <Paper elevation={4} style={{width: '100%'}}>*/}
          {/*        <div style={{padding: '15px'}}>*/}
          {/*          <GameSelector gameID={gameID} />*/}
          {/*        </div>*/}
          {/*      </Paper>*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}

          <Grid item xs={12}>
            <Paper elevation={4} style={{width: '100%'}}>
              <div style={{padding: '15px'}}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      value={localSMGameID}
                      onChange={e => setLocalSMGameID(e.target.value)}
                      label="Spiel ID aus Saisonmanager"
                      variant={"outlined"}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      value={localSMLeagueID}
                      onChange={e => setLocalSMLeagueID(e.target.value)}
                      label="Liga ID aus Saisonmanager"
                      variant={"outlined"}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      onClick={updateSaisonmanager}
                      color={'primary'}
                      disabled={
                        localSMGameID === smGameID
                        && localSMLeagueID === smLeagueID
                      }
                      variant={"contained"}
                      fullWidth
                    >Speichern</Button>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={6} alignItems={"center"}>
            <Grid item xs={12} style={{marginBottom: 10}}>
              <Paper elevation={4} style={{width: '100%'}}>
                <TextField placeholder={'mm:ss'} value={localTime} onChange={e => setLocalTime(e.target.value)} />
                <Button onClick={() => toggleTimer(moment(localTime, 'mm:ss'))} >Speichern</Button>
              </Paper>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={4} style={{width: '100%'}}>
                  <Typography align={"center"} variant={'h4'} style={{padding: '15px'}}>
                    {useSocket ? time : timeOutput}
                  </Typography>
                </Paper>
              </Grid>

              {!useSocket && (
                <>
                  <Grid item xs={12}>
                    <Paper elevation={4} style={{width: '100%'}}>
                      <Button
                        onClick={() => toggleTimer()}
                        color={'primary'}
                        variant={"contained"}
                        fullWidth
                        size={"large"}
                        disabled={useSocket}
                      >{isPlaying ? 'Stopp' : 'Start'}</Button>
                    </Paper>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                {!useSocket && (
                  <Paper elevation={4} style={{width: '100%'}}>
                    <Button
                      onClick={clearTimer}
                      color={'secondary'}
                      variant={"contained"}
                      disabled={useSocket}
                      fullWidth
                      size={"large"}
                    >Zurücksetzen</Button>
                  </Paper>
                )}

                <Grid item xs={12}>
                  <Paper elevation={4} style={{width: '100%'}}>
                    <Button
                      onClick={e => {
                        e.preventDefault()
                        console.log(window.event)
                        let newWindow = null;
                        if (window.event.shiftKey && window.event.altKey) {
                          newWindow = window.open('http://videowall.local/cgi-bin/shutdown.cgi', '_blank')
                        } else if (window.event.shiftKey) {
                          newWindow = window.open('http://videowall.local/cgi-bin/reboot.cgi', '_blank')
                        } else {
                          newWindow = window.open('http://videowall.local/cgi-bin/refresh.cgi', '_blank')
                        }

                        newWindow.close()
                      }}
                      color={'secondary'}
                      variant={"contained"}
                      disabled={fetchingVideoWall}
                      fullWidth
                      size={"large"}
                    >Anzeigentafel neu laden</Button>
                  </Paper>
                </Grid>
              </Grid>

              {/*<Grid item xs={12}>*/}
              {/*  <Paper elevation={4} style={{width: '100%'}}>*/}
              {/*    <form style={{padding: '15px'}} onSubmit={addBannerItem}>*/}
              {/*      <Grid container>*/}
              {/*        <Grid item xs={3}>*/}
              {/*          <TextField*/}
              {/*              value={newBannerTime}*/}
              {/*              onChange={e => setNewBannerTime(e.target.value)}*/}
              {/*              variant={"outlined"}*/}
              {/*              fullWidth*/}
              {/*          />*/}
              {/*        </Grid>*/}
              {/*        <Grid item xs={9}>*/}
              {/*          <TextField*/}
              {/*              value={newBannerTop}*/}
              {/*              onChange={e => setNewBannerTop(e.target.value)}*/}
              {/*              variant={"outlined"}*/}
              {/*              fullWidth*/}
              {/*          />*/}
              {/*        </Grid>*/}
              {/*        <Grid item xs={6}>*/}
              {/*          <TextField*/}
              {/*              value={newBannerBottomLeft}*/}
              {/*              onChange={e => setNewBannerBottomLeft(e.target.value)}*/}
              {/*              variant={"outlined"}*/}
              {/*              fullWidth*/}
              {/*          />*/}
              {/*        </Grid>*/}
              {/*        <Grid item xs={6}>*/}
              {/*          <TextField*/}
              {/*              value={newBannerBottomRight}*/}
              {/*              onChange={e => setNewBannerBottomRight(e.target.value)}*/}
              {/*              variant={"outlined"}*/}
              {/*              fullWidth*/}
              {/*          />*/}
              {/*        </Grid>*/}
              {/*        <Grid item xs={12}>*/}
              {/*          <Button*/}
              {/*              variant={"contained"}*/}
              {/*              color={"primary"}*/}
              {/*              fullWidth*/}
              {/*              type={"submit"}*/}
              {/*          >*/}
              {/*            Hinzufügen*/}
              {/*          </Button>*/}
              {/*        </Grid>*/}
              {/*      </Grid>*/}
              {/*    </form>*/}
              {/*  </Paper>*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <Paper elevation={4} style={{width: '100%'}}>*/}
              {/*    <List>*/}

              {/*      {bannerList.map((item, index) => {*/}
              {/*        return (*/}
              {/*            <ListItem button onClick={() => {toggleBannerItem(item.ID)}} key={index}>*/}
              {/*              <ListItemText*/}
              {/*                  primary={(item.Time ? item.Time + ' // ' : '') + item.TopBar}*/}
              {/*                  secondary={item.BottomBarLeft + ' // ' + item.BottomBarRight}*/}
              {/*              />*/}
              {/*            </ListItem>*/}
              {/*        );*/}
              {/*      })}*/}
              {/*      <Divider />*/}
              {/*      {playingList.map((item, index) => {*/}
              {/*        return (*/}
              {/*            <ListItem style={{background: '#ccc'}} button onClick={() => {toggleBannerItem(item.ID)}} key={index}>*/}
              {/*              <ListItemText*/}
              {/*                  primary={(item.Time ? item.Time + ' // ' : '') + item.TopBar}*/}
              {/*                  secondary={item.BottomBarLeft + ' // ' + item.BottomBarRight}*/}
              {/*              />*/}
              {/*            </ListItem>*/}
              {/*        );*/}
              {/*      })}*/}
              {/*    </List>*/}
              {/*  </Paper>*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={4} style={{width: '100%'}}>
              <div style={{padding: '15px'}}>
                <TextField
                  value={localPeriod}
                  onChange={e => setLocalPeriod(e.target.value)}
                  label="Abschnitt"
                  variant={"outlined"}
                  fullWidth
                />
                <br />
                <br />
                <Button
                  onClick={() => {
                    config.update({
                      'Period': localPeriod,
                    })
                  }}
                  color={'primary'}
                  disabled={localPeriod === period}
                  variant={"contained"}
                  fullWidth
                >Speichern</Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={6}>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={4} style={{width: '100%'}}>
                  <div style={{padding: '15px'}}>
                    <Switch
                      checked={hidePenalties}
                      onChange={e => config.update({hidePenalties: !hidePenalties})}
                    />
                    <Typography variant={'body2'} component={'span'}>Strafen ausblenden</Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={4} style={{width: '100%'}}>
                  <div style={{padding: '15px'}}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Switch
                          checked={mirrorStreamScore}
                          onChange={e => config.update({MirrorStreamScore: !mirrorStreamScore})}
                        />
                        <Typography variant={'body2'} component={'span'}>Spielstand spiegeln (Stream)</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Switch
                          checked={mirrorDataScore}
                          onChange={e => config.update({MirrorDataScore: !mirrorDataScore})}
                        />
                        <Typography variant={'body2'} component={'span'}>Spielstand spiegeln (Stream - Pause)</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Switch
                          checked={mirrorScore}
                          onChange={e => config.update({MirrorScore: !mirrorScore})}
                        />
                        <Typography variant={'body2'} component={'span'}>Spielstand spiegeln (Halle)</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={4}>
                            <Button disabled={useSocket} fullWidth style={{height: '100%'}} variant={"contained"} onClick={() => updateGoals('Home', parseInt(home, 10) - 1)}>-1</Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography style={{padding: '10px 0'}} align={"center"} variant={'h4'}>{useSocket ? homeScore : home}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Button disabled={useSocket} fullWidth style={{height: '100%'}} variant={"contained"} onClick={() => updateGoals('Home', parseInt(home, 10) + 1)}>+1</Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid container>
                          <Grid item xs={4}>
                            <Button disabled={useSocket} fullWidth style={{height: '100%'}} variant={"contained"} onClick={() => updateGoals('Visitor', parseInt(visitor, 10) - 1)}>-1</Button>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography style={{padding: '10px 0'}} align={"center"} variant={'h4'}>{useSocket ? visitorScore : visitor}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Button disabled={useSocket} fullWidth style={{height: '100%'}} variant={"contained"} onClick={() => updateGoals('Visitor', parseInt(visitor, 10) + 1)}>+1</Button>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper elevation={4} style={{width: '100%'}}>
                  <div style={{padding: '15px'}}>
                    <Grid container>
                      <Grid item xs={6} style={{paddingBottom: '15px'}}>
                        <Switch
                          checked={mirrorStreamNames}
                          onChange={e => config.update({MirrorStreamNames: !mirrorStreamNames})}
                        />
                        <Typography variant={'body2'} component={'span'}>Namen spiegeln (Stream)</Typography>
                      </Grid>
                      <Grid item xs={6} style={{paddingBottom: '15px'}}>
                        <Switch
                          checked={mirrorNames}
                          onChange={e => config.update({MirrorNames: !mirrorNames})}
                        />
                        <Typography variant={'body2'} component={'span'}>Namen spiegeln (Halle)</Typography>
                      </Grid>
                    </Grid>
                    <TextField
                      value={localHomeName}
                      onChange={e => setLocalHomeName(e.target.value)}
                      label="Heim"
                      variant={"outlined"}
                      fullWidth
                    />
                    <br />
                    <br />
                    <TextField
                      value={localVisitorName}
                      onChange={e => setLocalVisitorName(e.target.value)}
                      label="Gast"
                      variant={"outlined"}
                      fullWidth
                    />
                    <br />
                    <br />
                    <Button
                      onClick={() => {
                        config.update({
                          'Home': localHomeName,
                          'Visitor': localVisitorName
                        })
                      }}
                      color={'primary'}
                      disabled={localHomeName === homeName && localVisitorName === visitorName}
                      variant={"contained"}
                      fullWidth
                    >Speichern</Button>
                  </div>
                </Paper>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Penalty
              homeName={homeName}
              visitorName={visitorName}
              timeOutput={useSocket ? time : timeOutput}
              gameID={gameID}
              period={period}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default EditApp;
