import React, {useEffect, useState} from 'react';
import {Box, Grid, makeStyles} from "@material-ui/core";
import '../App.css';
import {useStyles} from "./styles";
import {useHomeScore, useTime, useVisitorScore} from "../Websocket";
import db from "../lib/firebaseconfig";
import moment from "moment";

const ScreenProvider = ({useSocket, timeOutput, home, visitor, homeName, visitorName, homeLogo, visitorLogo, period, mirrorScore, mirrorNames, gameID, hidePenalties}) => {
  const classes = useStyles()
  const time = useTime();
  const homeScore = useHomeScore();
  const visitorScore = useVisitorScore();
  const [penaltyList, setPenaltyList] = useState([]);

  // const penalties = db.ref(`/penalties/${gameID}`)
  // useEffect(() => {
  //   const listener = penalties.on('value', (snapshot) => {
  //     const values = snapshot.val();
  //     const localList = []
  //     if (values) {
  //       const keys = Object.keys(snapshot.val());
  //       keys.forEach(key => {
  //         let tmp = values[key];
  //         // const timeToEnd = moment.duration(
  //         //   moment((useSocket ? time : timeOutput), 'mm:ss').add(20 * (period - 1), 'minutes').diff(moment(tmp.StartTime, 'mm:ss').add(tmp.Duration, 'minutes'))
  //         // ).asSeconds()
  //         //
  //         // if (gameID && timeToEnd < 0) {
  //         //   console.log(timeToEnd, timeOutput)
  //           tmp.ID = key
  //           localList.push(tmp)
  //         // }
  //       })
  //     }
  //
  //     if (JSON.stringify(localList) !== JSON.stringify(penaltyList)) {
  //       setPenaltyList(localList)
  //     }
  //   })
  //
  //   return () => penalties.off('value', listener);
  // }, [gameID]);

  const penalties = db.ref(`/penalties/${gameID}`)
  useEffect(() => {
    const listener = penalties.on('value', (snapshot) => {
      const values = snapshot.val();
      const localList = []
      if (values) {
        const keys = Object.keys(snapshot.val());
        keys.forEach(key => {
          let tmp = values[key];
          // const timeToEnd = moment.duration(
          //   moment((useSocket ? time : timeOutput), 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(tmp.StartTime, 'm:ss').add(tmp.Duration, 'minutes'))
          // ).asSeconds()
          //
          //   console.log(timeToEnd, timeOutput)
          // if (gameID && timeToEnd < 0) {
          tmp.ID = key
          localList.push(tmp)
          // }
        })
      }


      // if (JSON.stringify(localList) !== JSON.stringify(penaltyList)) {
      setPenaltyList(localList)
      // }
    })

    return () => penalties.off('value', listener);
  }, [gameID]);

    return (
      <Box display={'flex'} className={[classes.texture, 'App'].join(' ')}>
          <Box className={classes.left}></Box>
          <Box className={classes.right}></Box>
          <Box className={classes.clock}>
              <Box component={'div'} style={{fontSize: '12vw', paddingLeft: '2%', width: '100%'}}>{period}</Box>
              <Box component={'div'} className={classes.clockLeft}>
                  {(useSocket ? time : timeOutput).toString()?.split(':')[0]}
              </Box>
              <Box component={'div'} className={classes.clockMiddle}>:</Box>
              <Box component={'div'} className={classes.clockRight}>
                  {(useSocket ? time : timeOutput).toString()?.split(':')[1]}
              </Box>
          </Box>
          {!hidePenalties && ['Home', 'Visitor'].map(team => (
            <Box className={classes[`penalty${team}`]}>
              {penaltyList.map(penalty => {
                if (mirrorNames ? penalty.Team === team : penalty.Team !== team) {
                  return null
                }
                const timeToEnd = moment.duration(
                  moment((useSocket ? time : timeOutput), 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(penalty.StartTime, 'm:ss').add(penalty.Duration, 'minutes'))
                ).asSeconds()

                if (timeToEnd < 0) {
                  const timeToStart = moment.duration(
                    moment((useSocket ? time : timeOutput), 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(penalty.StartTime, 'm:ss'))
                  ).asSeconds()

                  if (timeToStart >= 0) {
                    const dur = moment(timeToEnd * -1000, 'x')
                    return (
                      <Box className={classes[`penaltyItem${team}`]}>
                        {/*<Box className={classes.penaltyItemNumber}>{penalty.PlayerNo}</Box>*/}
                        <Box className={classes.penaltyItemTime}>{dur.format('m:ss')}</Box>
                      </Box>
                    )
                  }
                }
              })}
            </Box>
          ))}
          {/*    <Box className={classes.penaltyItem}>*/}
          {/*        <Box className={classes.penaltyItemNumber}>6</Box>*/}
          {/*        <Box className={classes.penaltyItemTime}>1:45</Box>*/}
          {/*    </Box><Box className={classes.penaltyItem}>*/}
          {/*        <Box className={classes.penaltyItemNumber}>6</Box>*/}
          {/*        <Box className={classes.penaltyItemTime}>1:45</Box>*/}
          {/*    </Box>*/}
          {/*</Box>*/}
          {/*  <Box className={classes.penaltyVisitor}>*/}
          {/*      <Box className={classes.penaltyItemVisitor}>*/}
          {/*          <Box className={classes.penaltyItemNumber}>6</Box>*/}
          {/*          <Box className={classes.penaltyItemTime}>1:45</Box>*/}
          {/*      </Box><Box className={classes.penaltyItemVisitor}>*/}
          {/*      <Box className={classes.penaltyItemNumber}>6</Box>*/}
          {/*      <Box className={classes.penaltyItemTime}>1:45</Box>*/}
          {/*  </Box>*/}
          {/*</Box>*/}
          <Box className={classes.home} style={{textShadow: '0 0 35px #000'}}>
            {
              useSocket
                ? (!mirrorScore ? homeScore : visitorScore)
                : (!mirrorScore ? home : visitor)
            }
          </Box>
          <Box className={classes.homeImage}>
            {(homeLogo !== '') && (<img src={!mirrorNames ? homeLogo : visitorLogo} />)}
              {/*<img src={'https://www.baltic-storms.de/assets/balticstorms/Images/Logos/logo_farbig_weisser-rand.svg'} />*/}
          </Box>
          <Box className={classes.visitorImage}>
            {visitorLogo !== '' && (<img src={!mirrorNames ? visitorLogo : homeLogo} />)}
              {/*<img src={'EBB_Juniors_Logo_2014_dark_BG.png'} />*/}
          </Box>
          <Box className={classes.visitor} style={{textShadow: '0 0 35px #000'}}>
            {
              useSocket
                ? (!mirrorScore ? visitorScore : homeScore)
                : (!mirrorScore ? visitor : home)
            }
          </Box>
      </Box>
    );
}

export default ScreenProvider;
