import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import firebase from "firebase";

export default function Passwort(props) {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const handleClose = () => {
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((user) => {
                console.log('signed in')
                props.setLoginSubmitted(true)
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
            });
    };

    return (
        <div>
            <Dialog open={props.open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Passwort</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Gib zum Bearbeiten bitte das Passwort ein
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Passwort"
                        type="password"
                        fullWidth
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Abschicken
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
