import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  left: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '41%',
    height: 0,
    zIndex: 2,
    background: 'linear-gradient(270deg, rgba(42,65,140,1) 0%, rgba(59,80,152,1) 100%)',
    borderWidth: '100vh 15vw 0 0',
    borderColor: '#2A418C transparent transparent transparent',
    borderStyle: 'solid',
  },
  right: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '59vw',
    height: '100vh',
    zIndex: 1,
    background: 'linear-gradient(270deg, rgba(54,75,147,1) 0%, rgba(72,92,163,1) 100%)',
  },
  texture: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
    '&::after': {
      position: 'absolute',
      display: 'block',
      width: '100vw',
      height: '100vh',
      opacity: 0.2,
      zIndex: 3,
      background: 'url("texture.png")',
      content: '\' \''
    }
  },
  penaltyHome: {
    position: 'absolute',
    width: '50%',
    zIndex: 5,
    bottom: '15px',
    left: '0',
    fontSize: '11vw',
    letterSpacing: '-6px',
    color: '#fff',
    fontFamily: 'Work Sans',
    textTransform: 'uppercase',
    // fontStyle: 'italic',
    fontWeight: 'regular',
    // fontFamily: 'He\'s Dead Jim',
    lineHeight: 1
  },
  penaltyVisitor: {
    position: 'absolute',
    width: '50%',
    zIndex: 5,
    bottom: '15px',
    right: '0',
    fontSize: '11vw',
    letterSpacing: '-6px',
    color: '#fff',
    fontFamily: 'Work Sans',
    textTransform: 'uppercase',
    // fontStyle: 'italic',
    fontWeight: 'regular',
    // fontFamily: 'He\'s Dead Jim',
    lineHeight: 1
  },
  penaltyItemHome: {
    display: 'flex',
    margin: '20px 0'
  },
  penaltyItemVisitor: {
    display: 'flex',
    margin: '20px 0',
    flexDirection: 'row-reverse',
    '& .penaltyItemNumber': {
      padding: '0 5% 0 6%',
    }
  },
  penaltyItemNumber: {
    boxSizing: 'border-box',
    padding: '0 4% 0 1%',
    background: 'rgba(41,57,113,0.9)',
    textShadow: '0 0 35px #000',
    fontWeight: 300,
    color: '#afafaf'
  },
  penaltyItemTime: {
    background: 'rgb(19,27,52)',
    padding: '0 2%',
    width: '23vw',
    textAlign: 'left',

  },
  clock: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    zIndex: 5,
    bottom: '15px',
    left: '49vw',
    fontSize: '16vw',
    transform: 'translateX(-50%)',
    color: '#fff',
    fontFamily: 'He\'s Dead Jim',
    lineHeight: 1,
    flexWrap: 'wrap'
  },
  clockMiddle: {
    position: 'absolute',
    left: '50%',
    bottom: 0,
    transform: 'translateX(-50%)',
  },
  clockLeft: {
    width: '50%',
    textAlign: 'right',
    paddingRight: '3%',
    boxSizing: 'border-box',
    '&::after': {
      position: 'relative',
      display: 'block',
      width: '10px',
      height: '100px',
      zIndex: 3,
      content: ' : '
    }
  },
  clockRight: {
    width: '50%',
    textAlign: 'left',
    boxSizing: 'border-box',
    paddingLeft: '3%',
  },
  home: {
    // background: 'red',
    position: 'absolute',
    zIndex: 5,
    top: '50px',
    // left: '32vw',
    right: '58vw',
    fontSize: '22vw',
    // transform: 'translateX(-50%)',
    color: '#fff',
    textShadow: '0 0 35px #000',
    fontFamily: 'He\'s Dead Jim',
  },
  visitor: {
    // background: 'red',
    position: 'absolute',
    zIndex: 4,
    top: '50px',
    // right: '16vw',
    left: '58vw',
    fontSize: '22vw',
    // transform: 'translateX(-50%)',
    color: '#fff',
    textShadow: '0 0 35px #000 !important',
    fontFamily: 'He\'s Dead Jim'
  },
  homeImage: {
    position: 'absolute',
    top: '50px',
    // transform: 'translateY(-120%)',
    zIndex: 4,
    width: '25vw',
    marginTop: '50px',
    marginLeft: '2vw',
    '& img': {
      maxWidth: '100%',
      maxHeight: '15vw'
    }
  },
  visitorImage: {
    position: 'absolute',
    top: '50px',
    // transform: 'translateY(-120%)',
    right: 0,
    zIndex: 4,
    width: '25vw',
    marginTop: '50px',
    marginRight: '2vw',
    '& img': {
      maxWidth: '100%',
      maxHeight: '15vw'
    }
  }
}));
