import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, Paper, Typography} from "@material-ui/core";
import ConfirmDialog from "./ConfirmDialog";
import db from "../lib/firebaseconfig";
import moment from "moment";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const Penalty = ({ homeName, visitorName, timeOutput, gameID, period }) => {
  const [startTime, setStartTime] = useState('')
  const [duration, setDuration] = useState('')
  const [team, setTeam] = useState('')
  const [open, setOpen] = useState(false)
  const [deleteID, setDeleteID] = useState('')
  const [penaltyList, setPenaltyList] = useState([]);

  // console.log(homeName, visitorName, timeOutput, gameID, period)

  const penalties = db.ref(`/penalties/${gameID}`)
  useEffect(() => {
    const listener = penalties.on('value', (snapshot) => {
      const values = snapshot.val();
      const localList = []
      if (values) {
        const keys = Object.keys(snapshot.val());
        keys.forEach(key => {
          let tmp = values[key];
          const timeToEnd = moment.duration(
            moment(timeOutput, 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(tmp.StartTime, 'm:ss').add(tmp.Duration, 'minutes'))
          ).asSeconds()

      // console.log(timeToEnd)
          // if (gameID && timeToEnd < 0) {
            // console.log(timeToEnd, timeOutput)
            tmp.ID = key
            localList.push(tmp)
          // }
        })
      }

      // if (JSON.stringify(localList) !== JSON.stringify(penaltyList)) {
        setPenaltyList(localList)
      // }
    })

    return () => penalties.off('value', listener);
  }, [gameID]);

  // console.log(penaltyList)

  const handleOpen = (d, t) => {
    setTeam(t);
    setDuration(d);
    setStartTime(timeOutput);
    setOpen(true)
  }

  const addPenalty = playerNo => {
    penalties.push({
      StartTime: moment(startTime, 'mm:ss').add(20 * (period - 1), 'minutes').format('m:ss'),
      Duration: duration,
      PlayerNo: playerNo,
      Team: team
    }).then(e => {
      setOpen(false)
    })
  }

  return (
    <>
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        duration={duration}
        team={team}
        homeName={homeName}
        visitorName={visitorName}
        timeOutput={timeOutput}
        startTime={startTime}
        setStartTime={setStartTime}
        addPenalty={addPenalty}
      />
      <Dialog open={deleteID} onClose={() => setDeleteID('')}>
        <DialogTitle>Wirklich aufheben?</DialogTitle>
        <DialogActions>
          <Button onClick={() => {
            penalties.child(deleteID).remove().then(e => {
              setDeleteID('')
            })
          }} variant={"contained"} color={"secondary"}>Aufheben</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper style={{padding: '10px'}}>
            <Box item xs={6} paddingBottom={2}>
              <Typography variant={'h6'}>{homeName}</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Button onClick={() => handleOpen(2, 'Home')} fullWidth variant={"contained"}>2'</Button>
              </Grid>
              {/*<Grid item xs={6}>*/}
              {/*  <Button onClick={() => handleOpen(5, 'Home')} fullWidth variant={"contained"}>5min</Button>*/}
              {/*</Grid>*/}
              <Grid item xs={12} md={6}>
                <Button onClick={() => handleOpen(10, 'Home')} fullWidth variant={"contained"}>10'</Button>
              </Grid>
              {penaltyList.map(penalty => {
                if (penalty.Team !== 'Home') {
                  return null
                }

                const timeToEnd = moment.duration(
                  moment(timeOutput, 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(penalty.StartTime, 'm:ss').add(penalty.Duration, 'minutes'))
                ).asSeconds()

                if (timeToEnd < 0) {
                  const timeToStart = moment.duration(
                    moment(timeOutput, 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(penalty.StartTime, 'm:ss'))
                  ).asSeconds()

                  if (timeToStart >= 0) {
                    return (
                      <>
                        <Grid item xs={3}>
                          <Typography style={{padding: '10px 0'}}>{penalty.PlayerNo && 'Nr.'} {penalty.PlayerNo}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography style={{padding: '10px 0'}}>{penalty.Duration} min</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography style={{padding: '10px 0'}}>Ab {penalty.StartTime}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton onClick={() => {
                            setDeleteID(penalty.ID)
                          }}>
                            <DeleteOutlineIcon/>
                          </IconButton>
                        </Grid>
                      </>
                    )
                  }
                }
              })}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper style={{padding: '10px'}}>
            <Box item xs={6} paddingBottom={2}>
              <Typography variant={'h6'}>{visitorName}</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Button onClick={() => handleOpen(2, 'Visitor')} fullWidth variant={"contained"}>2'</Button>
              </Grid>
              {/*<Grid item xs={6}>*/}
              {/*  <Button onClick={() => handleOpen(5, 'Visitor')} fullWidth variant={"contained"}>5min</Button>*/}
              {/*</Grid>*/}
              <Grid item xs={12} md={6}>
                <Button onClick={() => handleOpen(10, 'Visitor')} fullWidth variant={"contained"}>10'</Button>
              </Grid>
              {penaltyList.map(penalty => {
                if (penalty.Team !== 'Visitor') {
                  return null
                }
                const timeToEnd = moment.duration(
                  moment(timeOutput, 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(penalty.StartTime, 'm:ss').add(penalty.Duration, 'minutes'))
                ).asSeconds()

                if (timeToEnd < 0) {
                  const timeToStart = moment.duration(
                    moment(timeOutput, 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(penalty.StartTime, 'm:ss'))
                  ).asSeconds()

                  if (timeToStart >= 0) {
                    return (
                      <>
                        <Grid item xs={3}>
                          <Typography style={{padding: '10px 0'}}>{penalty.PlayerNo && 'Nr.'} {penalty.PlayerNo}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography style={{padding: '10px 0'}}>{penalty.Duration} min</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography style={{padding: '10px 0'}}>Ab {penalty.StartTime}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton onClick={() => {
                            setDeleteID(penalty.ID)
                          }}>
                            <DeleteOutlineIcon/>
                          </IconButton>
                        </Grid>
                      </>
                    )
                  }
                }
              })}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

Penalty.propTypes = {}

export default Penalty;
