import { createClient, dedupExchange, fetchExchange } from 'urql';

const GRAPHQL_ENDPOINT = `https://www.baltic-storms.de/ticker`;

/**
 * URQL Client for silverstripe
 *
 * @returns {*}
 */
const GraphQLClientSilverstripe = createClient({
  url: GRAPHQL_ENDPOINT,
  fetchOptions: () => {
    const lang = window.localStorage.getItem('i18nextLng')
    return {
      headers: {
        'StormsAuth': 'hibSDIjmnIKYk3135xY_eReqTUi87Bklvh3MsP7VA',
        'Origin': window.origin
      }
    }
  },
  exchanges: [
    dedupExchange,
    fetchExchange
  ],
  requestPolicy: 'cache-and-network'
});

export default GraphQLClientSilverstripe;
