import React, {useCallback, useEffect, useMemo, useState} from 'react';
import moment from "moment";
import db, {dbTime} from "./lib/firebaseconfig";
import {HashRouter, Route, Switch} from "react-router-dom";
import EditApp from "./EditApp";
import Screen from "./Screen";
import LiveStream from "./LiveStream";
import LiveStreamData from "./LiveStreamData";
import SocketSender from "./SocketSender";
import {timeToMs} from "./lib/helpers";

const Gameboard = ({}) => {
    const [useSocket, setUseSocket] = useState(false);
    const [gameID, setGameID] = useState(0);
    const [smGameID, setSMGameID] = useState(0);
    const [smLeagueID, setSMLeagueID] = useState(0);
    const [period, setPeriod] = useState('');
    const [isPlaying, setPlaying] = useState(false)
    const [time, setTime] = useState(0)
    const [timeOutput, setTimeOutput] = useState(0)
    const [startTime, setStartTime] = useState(0)
    const [hidePenalties, setHidePenalties] = useState(true);
    const [home, setHome] = useState(0);
    const [homeLogo, setHomeLogo] = useState('');
    const [visitor, setVisitor] = useState(0);
    const [visitorLogo, setVisitorLogo] = useState('');
    const [homeName, setHomeName] = useState('');
    const [visitorName, setVisitorName] = useState('');
    const [latenz, setLatenz] = useState(0);
    const [mirrorStreamScore, setMirrorStreamScore] = useState(false);
    const [mirrorDataScore, setMirrorDataScore] = useState(false);
    const [mirrorScore, setMirrorScore] = useState(false);
    const [mirrorStreamNames, setMirrorStreamNames] = useState(false);
    const [mirrorNames, setMirrorNames] = useState(false);

    const config = useMemo(() => db.ref(`/config`), []);
    const board = useMemo(() => db.ref(`/board/${gameID}`), [gameID])
    const offsetRef = useMemo(() => db.ref(".info/serverTimeOffset"), []);

    console.log(visitor)

    useEffect(() => {
        const listener = offsetRef.on("value", function(snap) {
            if (latenz !== snap.val()) {
                setLatenz(snap.val())
            }
        });

        return () => offsetRef.off('value', listener);
    }, [])

    useEffect(() => {
        const listener = config.on('value', snap => {
            if (gameID !== snap.val()['CurrentSMGame']) {
              // if (gameID !== snap.val()['CurrentGame']) {
              // setGameID(snap.val()['CurrentGame'])
              setGameID(snap.val()['CurrentSMGame'])
            }
            if (smGameID !== snap.val()['CurrentSMGame']) {
                setSMGameID(snap.val()['CurrentSMGame'])
            }
            if (smLeagueID !== snap.val()['CurrentSMLeague']) {
                setSMLeagueID(snap.val()['CurrentSMLeague'])
            }
            if (useSocket !== snap.val()['socket']) {
                setUseSocket(snap.val()['socket'])
            }
            if (homeName !== snap.val()['Home']) {
                setHomeName(snap.val()['Home'])
            }

            setHomeLogo(snap.val()['HomeLogo'])

            if (visitorName !== snap.val()['Visitor']) {
                setVisitorName(snap.val()['Visitor'])
            }

            setVisitorLogo(snap.val()['VisitorLogo'])

            setMirrorStreamScore(snap.val()['MirrorStreamScore'])
            setMirrorDataScore(snap.val()['MirrorDataScore'])
            setMirrorScore(snap.val()['MirrorScore'])
            setMirrorStreamNames(snap.val()['MirrorStreamNames'])
            setMirrorNames(snap.val()['MirrorNames'])

            if (period !== snap.val()['Period']) {
                setPeriod(snap.val()['Period'])
            }

          setHidePenalties(snap.val()['hidePenalties'])
        });

        return () => config.off('value', listener);
    }, [])

    const updateTime = useCallback(() => {
        if (isPlaying) {
            const diff = moment(moment().add(latenz, 'ms').diff(moment(startTime, 'x'))).add(time).utc()
            const hours = moment.duration(diff.format('x')).hours()
            const minutes = moment.duration(diff.format('x')).minutes()
            const seconds = moment.duration(diff.format('x')).seconds()
            setTimeOutput(`${(hours.valueOf() * 60 + minutes.valueOf()).pad(2)}:${seconds.valueOf().pad(2)}`)
        } else {
            const hours = moment.duration(time?.valueOf() || 0).hours()
            const minutes = moment.duration(time?.valueOf() || 0).minutes()
            const seconds = moment.duration(time?.valueOf() || 0).seconds()
            setTimeOutput(`${(hours.valueOf() * 60 + minutes.valueOf()).pad(2)}:${seconds.valueOf().pad(2)}`)
        }
    }, [isPlaying, startTime, time, board])

    const updateFromDBTimer = useCallback((data, name) => {
        if (data) {
            const key = Object.keys(data)[0]
            const newTimerValue = data[key];

            // if (newTimerValue.Home !== home) {
                setHome(newTimerValue.Home)
            // }

            // if (newTimerValue.Visitor !== visitor) {
                setVisitor(newTimerValue.Visitor)
            // }

            if (newTimerValue.Timestamp !== startTime) {
                setStartTime(newTimerValue.Timestamp)
            }

            const timeMs = newTimerValue.Time
                ? timeToMs(newTimerValue.Time)
                : timeToMs(`${newTimerValue.Minuten}:${newTimerValue.Sekunden}.0`)

            if (timeMs !== time) {
                setTime(timeMs)
            }

            if ((newTimerValue.Status === 'start') !== isPlaying) {
                setPlaying(newTimerValue.Status === 'start')
            }
        } else {
            if (startTime !== 0) {
                setStartTime(0)
            }

            if (time !== 0) {
                setTime(0)
            }

            if (isPlaying) {
                setPlaying(false)
            }
        }
    }, [startTime, time, isPlaying, board, gameID])

    useEffect(() => {
        if (!isPlaying) {
            updateTime()
        }
    }, [time, isPlaying, updateTime])

    useEffect(() => {
        let timerInterval
        if (isPlaying) {
            timerInterval = setInterval(updateTime, 100)
        }

        if (!isPlaying) {
            clearInterval(timerInterval)
        }

        return () => clearInterval(timerInterval)
    }, [startTime, isPlaying, updateTime])

    useEffect(() => {
        const listener = board.limitToLast(1).on('value', (snapshot) => {
            updateFromDBTimer(snapshot.val());
        });

        return () => board.off('value', listener);
    }, [board, gameID, updateFromDBTimer])

    const toggleTimer = (newTime = false) => {
      console.log(newTime)
        if (isPlaying || newTime) {
            const tmpTime = newTime || moment(moment().diff(moment(startTime, 'x'))).add(time).add(latenz, 'ms').utc()
            board.push({
                Status: 'stop',
                Time: `${tmpTime.format('mm:ss')}.${tmpTime.milliseconds()}`,
                Minuten: tmpTime.format('mm'),
                Sekunden: tmpTime.format('ss'),
                Home: home,
                Visitor: visitor,
                Timestamp: dbTime
            }).then(error => {console.log(error)});
        } else {
            board.push({
                Status: 'start',
                Time: `${moment(time, 'x').format('mm:ss')}.${moment(time, 'x').milliseconds()}`,
                Minuten: `${moment(time, 'x').format('mm')}`,
                Sekunden: `${moment(time, 'x').format('ss')}`,
                Home: parseInt(home, 10),
                Visitor: parseInt(visitor, 10),
                Timestamp: dbTime
            });
        }
    }

    const clearTimer = () => {
        board.push({
            Status: 'stop',
            Time: '0:00',
            Minuten: '0',
            Sekunden: '0',
            Home: home,
            Visitor: visitor,
            Timestamp: dbTime
        });
    }

    const updateGoals = (type, value) => {
      console.log(type, value)
        board.limitToLast(1).get().then(snap => {
            const entry = snap.val()

            if (entry) {
                const keys = Object.keys(entry)
                board.child(keys[0]).update({
                    [type]: value
                })
            }
        }).catch(error => console.log(error))
    }

    const props = {
        time: time,
        timeOutput: timeOutput,
        useSocket: useSocket,
        home: home,
        visitor: visitor,
        homeName: homeName,
        homeLogo: homeLogo,
        visitorName: visitorName,
        visitorLogo: visitorLogo,
        period: period,
        gameID: gameID,
        mirrorStreamScore: mirrorStreamScore,
        mirrorDataScore: mirrorDataScore,
        mirrorScore: mirrorScore,
        mirrorStreamNames: mirrorStreamNames,
        mirrorNames: mirrorNames,
      hidePenalties: hidePenalties
    }

    return (
      <HashRouter>
          <Switch>
              <Route exact path='/send' render={() => <SocketSender {...props} />} />
              <Route exact path='/data' render={() => <LiveStreamData {...props} />} />
              <Route exact path='/stream' render={() => <LiveStream {...props} />} />
              <Route exact path='/screen' render={() => <Screen {...props} />} />
              <Route exact path='/' render={() => (
                <EditApp
                  useSocket={useSocket}
                  gameID={gameID}
                  smGameID={smGameID}
                  smLeagueID={smLeagueID}
                  home={home}
                  visitor={visitor}
                  homeName={homeName}
                  visitorName={visitorName}
                  period={period}
                  timeOutput={timeOutput}
                  isPlaying={isPlaying}
                  toggleTimer={toggleTimer}
                  clearTimer={clearTimer}
                  updateGoals={updateGoals}
                  mirrorStreamScore={mirrorStreamScore}
                  mirrorDataScore={mirrorDataScore}
                  mirrorScore={mirrorScore}
                  mirrorStreamNames={mirrorStreamNames}
                  mirrorNames={mirrorNames}
                  hidePenalties={hidePenalties}
                />
              )} />
          </Switch>
      </HashRouter>
    );
}

Gameboard.propTypes = {}

export default Gameboard;
