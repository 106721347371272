import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Switch} from 'react-router-dom'
import './index.css';
import reportWebVitals from './reportWebVitals';
import Gameboard from "./Gameboard";
import { Provider as URQLProvider } from 'urql';
import GraphQLClientSilverstripe from './lib/URQLClientSilverstripe';
import Websocket from "./Websocket";

ReactDOM.render(
  <React.StrictMode>
    <URQLProvider value={GraphQLClientSilverstripe}>
      <Websocket>
        <Gameboard />
      </Websocket>
    </URQLProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
