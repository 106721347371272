import React, {Component} from "react";
import Grow from "@material-ui/core/Grow";
import moment from 'moment';

export default class Banner extends Component {

    constructor() {
        super();
        this.state = {
            fadeIn: false
        }
    }

    componentDidMount() {
        const interval = 100;
        this.interval = setInterval(() => this.forceUpdate(), interval);
        // this.setState({
        //     fadeIn: true
        // })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const newFadeIn = moment(this.props.item.DisplayEnd, 'x').format('x') >= moment().format('x')
            && moment(this.props.item.DisplayStart, 'x').format('x') <= moment().format('x')
        if (newFadeIn !== this.state.fadeIn) {
            this.setState({
                fadeIn: newFadeIn
            })
        }
    }

    componentWillUnmount() {
        this.setState({
            fadeIn: false
        })
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const item = this.props.item;

        return (
            <Grow
                in={this.state.fadeIn}
                mountOnEnter={true}
                unmountOnExit={true}

            >
                <div>
                    <div className={'banner__top'}>
                        <div className={'banner__top--time'}>{item.Time}</div>
                        <div className={'banner__top--title'}>{item.TopBar}</div>
                    </div>
                    {item.BottomBarLeft !== '' && item.BottomBarRight !== '' && (
                        <div className={'banner__bottom'}>
                            <div className={'banner__bottom--left'}>{item.BottomBarLeft}</div>
                            <div className={'banner__bottom--right'}>{item.BottomBarRight}</div>
                        </div>
                    )}
                </div>
            </Grow>
        )
    }
}
