import firebase from "firebase";

const config = {
    apiKey: "AIzaSyBzo-Zb74yDKjvQl4XgqZuHxxzvelZfV6o",
    authDomain: "anzeigentafel.firebaseapp.com",
    databaseURL: "https://anzeigentafel-default-rtdb.firebaseio.com",
    storageBucket: "anzeigentafel.appspot.com",
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

export default firebase.database();

export let dbTime = firebase.database.ServerValue.TIMESTAMP;
