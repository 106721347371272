import React, {useEffect, useState} from "react";
// import '../App.css';
import db from "../lib/firebaseconfig";
import {replaceUmlauts} from "../lib/helpers";
import Banner from "./Banner";
import {useHomeScore, useTime, useVisitorScore} from "../Websocket";
import moment from "moment";

Number.prototype.pad = function (size) {
  var s = String(this);
  while (s.length < (size || 2)) {
    s = "0" + s;
  }
  return s;
}

const LiveStreamProvider = ({useSocket, timeOutput, home, visitor, homeName, visitorName, period, gameID, mirrorStreamScore, mirrorStreamNames, hidePenalties}) => {
  const [bannerList, setBannerList] = useState([]);
  const [penaltyList, setPenaltyList] = useState([]);
  const time = useTime();
  const homeScore = useHomeScore();
  const visitorScore = useVisitorScore();

  const penalties = db.ref(`/penalties/${gameID}`)
  useEffect(() => {
    const listener = penalties.on('value', (snapshot) => {
      const values = snapshot.val();
      const localList = []
      if (values) {
        const keys = Object.keys(snapshot.val());
        keys.forEach(key => {
          let tmp = values[key];
          // const timeToEnd = moment.duration(
          //   moment((useSocket ? time : timeOutput), 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(tmp.StartTime, 'm:ss').add(tmp.Duration, 'minutes'))
          // ).asSeconds()
          //
          //   console.log(timeToEnd, timeOutput)
          // if (gameID && timeToEnd < 0) {
            tmp.ID = key
            localList.push(tmp)
          // }
        })
      }


      // if (JSON.stringify(localList) !== JSON.stringify(penaltyList)) {
        setPenaltyList(localList)
      // }
    })

    return () => penalties.off('value', listener);
  }, [gameID]);

  // console.log(penaltyList)

  // useEffect(() => {
  //   console.log(gameID)
  //   if (gameID) {
  //
  //   penalties.push({
  //     StartTime: '0:10',
  //     Duration: 2,
  //     PlayerNo: 34,
  //     Team: 'Home'
  //   })
  //   }
  // }, [gameID])

  const banner = db.ref(`/banner/${gameID}`)
  banner.on('value', (snapshot) => {
    const values = snapshot.val();
    const localList = []
    if (values) {
      const keys = Object.keys(snapshot.val());
      keys.forEach(key => {
        if (values[key].DisplayStart > 0) {
          let tmp = values[key];
          tmp.ID = key
          localList.push(tmp)
        }
      })
    }

    if (JSON.stringify(localList) !== JSON.stringify(bannerList)) {
      setBannerList(localList)
    }
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const hideClock = urlParams.get('hideclock')
  const hideBanner = urlParams.get('hidebanner')

  // if (!pushed) {
  // banner.push({
  //   Time: '15:21',
  //   TopBar: 'Tor für Baltic Storms',
  //   BottomBarLeft: '6  Pascal Riedel',
  //   BottomBarRight: '21  Moritz Haupt',
  //   DisplayStart: 0,
  //   DisplayEnd: 0
  // })
  //   setPushed(1)
  // }

  return (
    <div className="App">
      <div className={'top-bar'}>
        <div className={'bar-home'}>
          <div style={{width: '100%', display: 'flex'}}>
            <div className={'bar-home__team'}>{!mirrorStreamNames ? replaceUmlauts(homeName) : replaceUmlauts(visitorName)}</div>
            <div className={'bar-home__score'}>
              {
                useSocket
                  ? (!mirrorStreamScore ? homeScore : visitorScore)
                  : (!mirrorStreamScore ? home : visitor)
              }
            </div>
          </div>
          <div className={'bar-home__penalty'}>
            {!hidePenalties && penaltyList.map(penalty => {
              const team = !mirrorStreamNames ? 'Home' : 'Visitor'
              if (penalty.Team !== team) {
                return null
              }
              const timeToEnd = moment.duration(
                moment((useSocket ? time : timeOutput), 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(penalty.StartTime, 'm:ss').add(penalty.Duration, 'minutes'))
              ).asSeconds()

              // console.log(timeToEnd)

              if (timeToEnd < 0) {
                const timeToStart = moment.duration(
                  moment((useSocket ? time : timeOutput), 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(penalty.StartTime, 'm:ss'))
                ).asSeconds()

                if (timeToStart >= 0) {
                  const dur = moment(timeToEnd * -1000, 'x')
                  return (
                    <div key={penalty.ID}className={'bar-home__penalty-item'}>
                      {dur.format('mm:ss')}
                    </div>
                  )
                }
              }
            })}
          </div>
        </div>
        <div className={'bar-visitor'}>
          <div style={{width: '100%', display: 'flex'}}>
            <div className={'bar-visitor__score'}>
              {
                useSocket
                  ? (!mirrorStreamScore ? visitorScore : homeScore)
                  : (!mirrorStreamScore ? visitor : home)
              }
            </div>
            <div className={'bar-visitor__team'}>{!mirrorStreamNames ? replaceUmlauts(visitorName) : replaceUmlauts(homeName)}</div>
          </div>
          <div className={'bar-visitor__penalty'}>
            {!hidePenalties && penaltyList.map(penalty => {
              const team = mirrorStreamNames ? 'Home' : 'Visitor'
              if (penalty.Team !== team) {
                return null
              }
              const timeToEnd = moment.duration(
                moment((useSocket ? time : timeOutput), 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(penalty.StartTime, 'm:ss').add(penalty.Duration, 'minutes'))
              ).asSeconds()

              if (timeToEnd < 0) {
                const timeToStart = moment.duration(
                  moment((useSocket ? time : timeOutput), 'm:ss').add(20 * (period - 1), 'minutes').diff(moment(penalty.StartTime, 'm:ss'))
                ).asSeconds()

                if (timeToStart >= 0) {
                  const dur = moment(timeToEnd * -1000, 'x')
                  return (
                    <div key={penalty.ID} className={'bar-visitor__penalty-item'}>
                      {dur.format('mm:ss')}
                    </div>
                  )
                }
              }
            })}
          </div>
        </div>
      </div>
      {!hideClock && (
        <div className={'time-bar'}>
          <div className={'time-bar__time'}>{useSocket ? time : timeOutput}</div>
          <div className={'time-bar__period'}>{period}</div>
        </div>
      )}

      {!hideBanner && (
        <div className={'banner'}>
          {bannerList.map((item, index) => item.DisplayStart !== 0 && item.DisplayEnd !== 0 && (
            <Banner key={JSON.stringify(item)} item={item} show={bannerList.length === index -1} />
          ))}
        </div>
      )}
    </div>
  );
}

export default LiveStreamProvider;
