import React, {createContext, useContext, useEffect, useState} from 'react';
import moment from "moment";
const WebSocket = require('isomorphic-ws');



const TimeContext = createContext();
const HomeContext = createContext();
const VisitorContext = createContext();
export const useTime = () => useContext(TimeContext);
export const useHomeScore = () => useContext(HomeContext);
export const useVisitorScore = () => useContext(VisitorContext);

const Websocket = ({children}) => {
  const [time, setTime] = useState('');
  const [homeScore, setHomeScore] = useState('');
  const [visitorScore, setVisitorScore] = useState('');
  const [dataTime, setDatatime] = useState('0:00');

  const updateData = data => {
    const localTime = `${pad(data.minutes, 2)}:${pad(data.seconds, 2)}`
    if (time !== localTime) {
      setTime(localTime)
    }
    setHomeScore(parseInt(data.home, 10));
    setVisitorScore(parseInt(data.visitor, 10));
  }
  const connect = () => {
    const ws = new WebSocket('wss://gameboard.schokoriedel.de/');

    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);

    let listener = null

    ws.addEventListener('open', () => {
      console.log('connected');
      console.log(urlParams.get('send'));
      if (urlParams.get('send') === '1') {
        listener = setInterval(() => {
          setDatatime(prev => {
            const newTime = moment(prev, 'm:ss').add(1, 'seconds')
            if (ws.readyState === 1) {
              ws.send(JSON.stringify({
                minutes: newTime.format('mm'),
                seconds: newTime.format('ss'),
                home: 0,
                visitor: 0
              }))
            }
            return newTime.format('mm:ss')
          })
        }, 1000)
      }
    });

    ws.addEventListener('close', () => {
      console.log('disconnected');
      clearInterval(listener)
      connect();
    });

    ws.addEventListener("message", event => {
      const data = JSON.parse(event.data)
      updateData(data)
    });

    ws.addEventListener('error', error => {
      console.error('Socket encountered error: ', error.message, 'Closing socket');
      clearInterval(listener)
      ws.close();
      // connect();
    })
  }

  useEffect(() => connect(), [])

  return (
    <TimeContext.Provider value={time}>
      <HomeContext.Provider value={homeScore}>
        <VisitorContext.Provider value={visitorScore}>
          {children}
        </VisitorContext.Provider>
      </HomeContext.Provider>
    </TimeContext.Provider>
  );
}

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

Websocket.propTypes = {}

export default Websocket;
